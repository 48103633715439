"use client";

import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { updateTermsAndConditions } from "../../../lib/api/authApi";
import { errorToast, successToast } from "../../../utils/toast";

const validationSchema = Yup.object({
  termsAccepted: Yup.boolean()
    .oneOf([true], "You must accept the terms and conditions")
    .required("Required"),
});

const TermsConditions = (props: any) => {
  console.log("props: ", props);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initialValues = {
    termsAccepted: false,
  };

  const updateTermsAndCondition = async () => {
    // setLoading(true);
    const res = await updateTermsAndConditions({ user_id: props.userId, terms_and_condition: 1 });
    if (res.data.status.code === 200 || res.data.status.code === 201) {
      successToast("Terms and Conditions Accepted.");
      setIsModalOpen(false);
      // setLoading(false);
    } else {
      errorToast("Something Went Wrong!");
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (props.isOpen) {
      setIsModalOpen(true);
    }
  }, [props.isOpen]);

  return (
    <div className="relative">
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full mx-4">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                console.log("Form submitted:", values);
                updateTermsAndCondition();
                // setIsModalOpen(false);
              }}
            >
              {({ values, handleChange, errors, touched, isSubmitting }) => (
                <Form className="w-full">
                  <div>
                    <h2 className="text-2xl font-bold mb-4 text-[rgba(80,200,120,1)] text-center">
                      Terms and Conditions
                    </h2>
                    <div className="max-h-96 overflow-y-auto mb-4 p-4">
                      <p className="text-sm font-bold text-center text-gray-600">
                        IntuEnergy Technologies Pvt. Ltd. (“IntuIONS”) Service Agreement
                      </p>
                      <p className="mt-4 text-sm text-gray-600">
                        This License Agreement (this “Agreement”) is entered into by and between
                        IntuEnergy Technologies Pvt. Ltd. (IntuIONS), a company incorporated under
                        the Companies Act 2013 and the Client who subscribed for accessing IntuIONS
                        IoT Software platform and/or its proprietary hardware (“Client”). IntuIONS
                        and Client are sometimes referred to in this Agreement individually as a
                        “Party” and collectively as the “Parties.”
                      </p>

                      <p className="mt-2 text-sm text-gray-600">
                        <strong>WHEREAS</strong> IntuIONS offers IoT Green Energy Platform as a
                        Service (GreEnPAAS) along with its proprietary IoT Hardware device (iTCU –
                        Intelligent Telematics Control Unit) to its Client including, but not
                        limited to EV and EV Component OEMs, Leasing & Financing Companies, Fleet
                        Operators, Aggregators, Insurance company, End users, Roadside Assistance
                        providers.
                      </p>
                      <p className="mt-2 text-sm text-gray-600">
                        <strong>“Service(s)”</strong> is defined as a subscription to access the
                        cloud-based offerings & other IoT related hardware, network management,
                        connectivity services, security services, and other related services,
                        including any Platform APIs or SDKs, mobile apps, provided by IntuIONS
                        through the Internet.
                      </p>
                      <p className="mt-2 text-sm text-gray-600">
                        <strong>WHEREAS</strong> Client wishes to use the aforesaid Service for
                        commercial usage and IntuIONS is willing to provide such rights of use,
                        subject to the conditions set forth in this Agreement.
                      </p>
                      <p className="mt-2 text-sm text-gray-600">
                        <strong>NOW THEREFORE</strong>, in consideration of the mutual promises and
                        covenants set forth herein and intending to be legally bound, the Parties
                        hereto agree as follows:
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Engagement of IntuIONS
                      </h3>
                      <h3 className="text-base font-bold mt-2 text-gray-600">
                        Scope of Engagement
                      </h3>
                      <p className="text-sm text-gray-600">
                        Subject to the terms and conditions of this Agreement, IntuIONS grants the
                        Client a limited, non-exclusive, non-sublicensable, revocable,
                        non-transferable license to:
                      </p>
                      <ul className="list-disc pl-10 text-sm text-gray-600">
                        <li>
                          Access and use the Services on its device solely in connection with its
                          use of the Services; and
                        </li>
                        <li>
                          Access and use any content, information, and related materials made
                          available through the Services, solely for its use only.
                        </li>
                        <li>Any rights not expressly granted herein are reserved by IntuIONS.</li>
                      </ul>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Compensation and Payment
                      </h3>
                      <p className="text-sm text-gray-600">Fees and Expenses</p>
                      <p className="text-sm text-gray-600">
                        Client will pay IntuIONS for Services according to the Subscription Charges
                        as mutually agreed.
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Client responsibilities
                      </h3>
                      <p className="text-sm text-gray-600">
                        By using the Services, client acknowledges that it is solely responsible and
                        assumes all risks for:
                      </p>
                      <ul className="list-disc pl-10 text-sm text-gray-600">
                        <li>
                          The data generated by Client through use of the Services, including
                          reliance by a third party on its data’s quality, accuracy, or reliability.
                        </li>
                        <li>
                          Complying with all applicable laws and regulations for the use of the
                          Services, including laws regarding the safe operation of vehicle/component
                          where our Services/hardware are being integrated.
                        </li>
                        <li>
                          Obtaining all necessary licenses and authorizations for operating any
                          hardware or complimentary software used in conjunction with the Services
                          in the area of operation.
                        </li>
                        <li>
                          Any incident involving Electric vehicles or its components, owing to
                          unforeseen circumstances, beyond IntuIONS control, including but not
                          limited to extremities of weather, fire, theft, crashes, failures, or
                          collisions with other objects or persons.
                        </li>
                        <li>
                          Sub-optimal quality of the vehicle/devices on which the Services were
                          deployed.
                        </li>
                        <li>
                          Any loss, liability, claim, or other demand resulting from the violation
                          of privacy rights of any individual or organization that might result from
                          its use of the Services.
                        </li>
                        <li>
                          Any loss, liability, claim, or other demand resulting from the violation
                          of any other law for the time being in force.
                        </li>
                      </ul>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Limitation of liability
                      </h3>
                      <p className="mt-1 text-sm text-gray-600">
                        To the extent allowed by applicable law, Client will indemnify “IntuIONS”
                        and its directors, officers, employees, and contractors for any third-party
                        legal proceedings (including actions by government authorities) arising out
                        of or relating to Client’s unlawful use of the Services or violation of
                        these terms or service-specific additional terms. This indemnity covers any
                        liability or expense arising from claims, losses, damages, judgments, fines,
                        litigation costs, and legal fees.
                      </p>
                      <p className="mt-1 text-sm text-gray-600">
                        IntuIONS will not be responsible for the following liabilities:
                      </p>
                      <ol className="list-decimal pl-10 mt-2 text-sm text-gray-600">
                        <li>
                          loss of profits, revenues, business opportunities, goodwill, or
                          anticipated savings
                        </li>
                        <li>indirect or consequential loss</li>
                      </ol>
                      <p className="text-sm text-gray-600">
                        IntuIONS’s total liability arising out of or relating to these terms is
                        limited to the fees paid by Client to use the relevant Services in the 12
                        months before the breach therein.
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Term and termination
                      </h3>
                      <p className="text-sm text-gray-600">Term.</p>
                      <p className="text-sm text-gray-600">
                        This Agreement is effective as of the Effective Date Client starts using the
                        Service and will continue in full force and effect until the subscription
                        tenure, unless earlier terminated as provided in this Agreement.
                      </p>
                      <p className="text-sm text-gray-600">Termination.</p>
                      <p className="text-sm text-gray-600">
                        Each Party may terminate this Agreement upon material breach by the other
                        Party of one or more of the terms and conditions of this Agreement, provided
                        that the breaching Party is notified in writing of the material breach and
                        such breach is not cured within thirty (30) days after receipt of such
                        written notice. Client’s termination of this Agreement will not relieve
                        Client of its obligations to pay for the subscription fee as agreed upon. If
                        “IntuIONS” terminates this Agreement due to Client’s default: (a) Client
                        shall, within (15 ) days of such termination, deliver to “IntuIONS” all
                        copies and portions of the Software and related materials and documentation
                        in its possession furnished by “IntuIONS” under this Agreement; and (b) all
                        amounts payable or accrued to “IntuIONS” under this Agreement shall become
                        immediately due and payable. Termination for breach will not alter or affect
                        the terminating Party’s right to exercise any other remedy for breach.
                        Client may terminate this Agreement without cause upon (15) days advance
                        written notice. In the event of termination without cause, Client agrees to
                        pay “IntuIONS” for the tenure of the subscription period.
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">Confidentiality</h3>
                      <h3 className="text-base font-bold mt-2 text-gray-600">
                        Client’s Confidential Information
                      </h3>
                      <p className="text-sm text-gray-600">
                        All information relating to Client that is known to be confidential or
                        proprietary, or which is clearly marked as such, will be held in confidence
                        by “IntuIONS” and will not be disclosed by ‘IntuIONS’ except to the extent
                        that such disclosure or use is reasonably necessary to the rendering of
                        Services and Quality Control purposes.
                      </p>
                      <h3 className="text-base font-bold mt-2 text-gray-600">
                        IntuIONS’s Confidential Information
                      </h3>
                      <p className="text-sm text-gray-600">
                        All information relating to “IntuIONS” that is known to be confidential or
                        proprietary, or which is clearly marked as such, will be held in confidence
                        by Client and will not be disclosed or used by Client except to the extent
                        that such disclosure or use is reasonably necessary to the performance of
                        Client’s duties and obligations under this Agreement.
                      </p>
                      <h3 className="text-base font-bold mt-2 text-gray-600">Notification</h3>
                      <p className="text-sm text-gray-600">
                        IntuIONS shall notify the Client as soon as possible in the event of an
                        actual breach of data that can be personally identified to a particular
                        client.
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Confidentiality and Restriction on Access to Competitors
                      </h3>
                      <ul className="list-disc pl-10 text-sm text-gray-600">
                        <li>
                          <strong>Non-Disclosure to Competitors:</strong> The Client agrees not to
                          disclose, share, or provide access to the <strong>“Service(s)”</strong> to
                          any third party that is a competitor of IntuIONS or may be reasonably
                          considered a competitor, without prior written consent from IntuIONS.
                        </li>
                        <li>
                          <strong>Definition of Competitor:</strong> A &quot;competitor&quot; is
                          defined as any entity that directly or indirectly competes with IntuIONS
                          in the development, consultancy, marketing, sale, or provision of similar
                          or substitute products, services, or technologies.
                        </li>
                        <li>
                          <strong>Approval Process:</strong> If the Client is unsure whether a
                          potential recipient of the <strong>“Service(s)”</strong> constitutes a
                          competitor, the Client must first discuss the identity of such potential
                          recipient with IntuIONS and seek written approval before providing access
                          to the <strong>“Service(s)”</strong>. he Client acknowledges that IntuIONS
                          reserves the right to refuse/restrict such access at its sole discretion.
                        </li>
                        <li>
                          <strong>Breach and Remedies:</strong> Any unauthorized disclosure of
                          access to the <strong>“Service(s)”</strong> to a competitor, or failure to
                          comply with this clause, will be considered a material breach of this
                          Agreement. In the event of such breach, IntuIONS may pursue any and all
                          remedies available under the law, including but not limited to injunctive
                          relief and monetary damages.
                        </li>
                      </ul>

                      <h3 className="text-base font-bold mt-4 text-gray-600">Survival</h3>
                      <p className="text-sm text-gray-600">
                        These obligations of confidentiality will extend for a period of 1 (one)
                        years after the termination of this Agreement, but will not apply with
                        respect to information that is independently developed by the Parties,
                        lawfully becomes a part of the public domain, or of which the Parties gain
                        knowledge or possession free of any confidentiality obligation
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">Proprietary Rights</h3>
                      <p className="text-sm text-gray-600">
                        Unless otherwise stated, all proprietary rights related to the Services are
                        owned and operated by ‘IntuIONS’. Unless otherwise specifically permitted in
                        writing, Client must not:
                      </p>
                      <ul className="list-disc pl-10 text-sm text-gray-600">
                        <li>
                          Reproduce, duplicate, copy, modify, prepare derivative works based upon,
                          distribute, license, lease, sell, resell, transfer, publicly display,
                          publicly perform, transmit, stream, broadcast, or otherwise exploit any
                          portion of the Services.
                        </li>
                        <li>
                          Modify, disassemble, decompile, or reverse engineer any of the Services.
                        </li>
                        <li>
                          Remove, circumvent, disable, damage, or otherwise interfere with
                          security-related features that prevent or restrict use or copying of any
                          portion of the Services.
                        </li>
                        <li>
                          Remove or delete any copyright, trademark, or other proprietary rights
                          notices from any portion of the Services.
                        </li>
                        <li>
                          Use our names, logos, product and service names, trademarks, or services
                          marks unless expressly permitted in writing.
                        </li>
                      </ul>
                      <p className="mt-2 text-sm text-gray-600">
                        Client own all the generated or posted data, messages, reviews, video, maps,
                        models, folders, text, photographs, images, and data gathered by an Electric
                        vehicle or device (“User Data”) Client post or submit on the Services. By
                        using the Service, Client grant ‘IntuIONS a non-exclusive, irrevocable,
                        fully paid and royalty-free, transferable, sub-licensable, worldwide license
                        to use, copy, reproduce, transmit, display, process, adapt, modify, publish,
                        distribute, and process its User Data for purposes incidental to this
                        contract as well as Quality Control.
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Third-Party Software/Hardware
                      </h3>
                      <p className="text-sm text-gray-600">
                        The Services may contain or be accompanied by software code provided by
                        third parties, as embedded in hardware (“Third Party Software/Hardware”)
                        that is subject to separate license terms (the “Third Party Terms”), and not
                        any license contained in these Terms. Its use of the Third-Party
                        Software/Hardware in conjunction with the Service in a manner consistent
                        with the Terms is permitted.
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Termination and Survival
                      </h3>
                      <p className="text-sm text-gray-600">
                        Client may terminate its account at any time. Client are not entitled to any
                        compensation whatsoever in the event of termination.
                      </p>
                      <p className="mt-1 text-sm text-gray-600">
                        The following Sections shall survive and remain in full force and effect
                        despite termination: Its Responsibilities, Proprietary Rights, Disclaimer of
                        Warranties, Limitation of Liability, Indemnity, as well as Governing Law and
                        Applicable Jurisdiction, along with clause related to User Data License.
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">Force Majeure</h3>
                      <p className="text-sm text-gray-600">
                        “Force Majeure” shall mean any event beyond the reasonable control of either
                        Party, as the case may be, and which is unavoidable notwithstanding the
                        reasonable care of the Party affected.
                      </p>
                      <p className="text-sm text-gray-600">
                        A Force Majeure shall include, without limitation, the following:
                      </p>
                      <p className="mt-1 text-sm text-gray-600">
                        War, hostilities or warlike operations (whether a state of war be declared
                        or not), invasion, act of foreign enemy, and civil war; Strike, lockout or
                        warlike operations (whether a state of war be declared or not), invasion,
                        act of foreign enemy, and civil war; earthquake, landslide, volcanic
                        activity, fire, flood or inundation, tidal wave, typhoon or cyclone,
                        hurricane, storm, lightning, or other inclement weather condition, nuclear
                        and pressure waves, or other natural or physical disaster - Covid-19 induced
                        lockdown or any subsequent variant thereof.
                      </p>
                      <ol className="list-[upper-roman] pl-10 text-sm text-gray-600">
                        <li>
                          If either party is prevented, hindered, or delayed from or in performing
                          any of its obligations under the Contract by an event of Force Majeure,
                          then it shall notify the other in writing of the occurrence of such event
                          and the circumstances of the event of Force Majeure within fifteen (15)
                          days after the occurrence of such event.
                        </li>
                        <li>
                          The party who has given such notice shall be excused from the performance
                          or punctual performance of its obligations under the Contract for so long
                          as the relevant event of Force Majeure continues and to the extent that
                          such party’s performance is prevented, hindered, or delayed. The time for
                          achieving Final Acceptance shall be extended.
                        </li>
                        <li>
                          The party or parties affected by the event of Force Majeure shall use
                          reasonable efforts to mitigate the effect of the event of Force Majeure
                          upon its or their performance of the Contract and to fulfill its or their
                          obligations under the Contract, but without prejudice to either party’s
                          right to terminate the Contract.
                        </li>
                        <li>
                          If the performance of the Contract is substantially prevented, hindered,
                          or delayed for a single period of more than sixty (60) days on account of
                          one or more events of Force Majeure during the time period covered by the
                          Contract, the parties will attempt to develop a mutually satisfactory
                          solution, failing which, either party may terminate the Contract by giving
                          a notice to the other.
                        </li>
                        <li>
                          For the avoidance of doubt, it is expressly clarified that the failure on
                          the part of the “IntuIONS” under this Agreement or the service levels to
                          implement any disaster contingency planning and back-up and other data
                          safeguards in accordance with the terms of this Agreement or the service
                          levels against natural disaster, fire, sabotage or other similar
                          occurrence shall not be deemed to be a Force Majeure event
                        </li>
                        <li>
                          For the “IntuIONS” to take benefit of this clause, it is a condition
                          precedent that the “IntuIONS” must promptly notify Client, in writing of
                          such conditions and the cause thereof within fifteen calendar days of the
                          arising of the Force Majeure event. Client shall study the submission of
                          the “IntuIONS” and inform whether the situation can be qualified one of
                          Force Majeure. Unless otherwise directed by Client in writing, the
                          “IntuIONS” shall continue to perform its obligations under the resultant
                          Agreement as far as it is reasonably practical and shall seek all
                          reasonable alternative means for performance of services not prevented by
                          the existence of a Force Majeure event.
                        </li>
                        <li>
                          In the event of delay in performance attributable to the presence of a
                          force majeure event, the time for performance shall be extended by a
                          period(s) equivalent to the duration of such delay. If the duration of
                          delay continues beyond a period of 30 days, Client and the “IntuIONS”
                          shall hold consultations with each other in an endeavor to find a solution
                          to the problem.
                        </li>
                      </ol>
                      <p className="mt-1 text-sm text-gray-600">
                        Notwithstanding anything to the contrary mentioned above, the decision of
                        IntuIONS shall be final and binding on the “IntuIONS”
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Representations & Warranties
                      </h3>
                      <p className="text-sm text-gray-600">Client represents that:</p>
                      <ul className="list-disc pl-10 text-sm text-gray-600">
                        <li>
                          Its use of Services complies with all applicable laws and regulations.
                        </li>
                        <li>
                          If it uses Services on behalf of another person or entity, it warrants
                          that it is eligible to bind the other person or entity to these Terms.
                        </li>
                        <li>
                          Its account will be terminated immediately and without compensation if it
                          uses the Services while disregarding these eligibility criteria.
                        </li>
                      </ul>

                      <h3 className="text-base font-bold mt-4 text-gray-600">
                        Disclaimer of Warranties
                      </h3>
                      <p className="text-sm text-gray-600">
                        Client expressly understands and agrees that the use of the Services is at
                        its sole risk. The Services are provided on an as-is-and-as-available basis.
                        ‘IntuIONS’ expressly disclaims all warranties of any kind, whether express
                        or implied, including, but not limited to, the implied warranties of
                        merchantability and fitness for a particular purpose. ‘IntuIONS’ makes no
                        warranty that the Services will be uninterrupted, timely, safe, secure, or
                        error-free. Use of any material downloaded or obtained using the Services
                        shall be at its own discretion and risk.
                      </p>
                      <p className="mt-1 text-sm text-gray-600">
                        No advice or information, whether written or oral, obtained by Client from
                        ‘IntuIONS’, its employees, or representatives shall create any warranty not
                        expressly stated in the terms.
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">Dispute Resolution</h3>
                      <p className="text-sm text-gray-600">
                        In case of dispute, in order to formally submit a Disputed Matter for
                        resolution, one Party (&quot;Claimant&quot;) shall give a written notice
                        (&quot;Dispute Notice&quot;) to the other Party. The Dispute Notice shall be
                        accompanied by (a) a statement by the Claimant describing the Disputed
                        Matter in reasonable detail and (b) documentation, if any, supporting the
                        Claimant&apos;s position on the Disputed Matter. The other Party
                        (&quot;Respondent&quot;) shall have the right to respond to the Dispute
                        Notice within 15 days after receipt of the Dispute Notice. In the event that
                        the parties are unable to resolve the Disputed Matter within a further
                        period of 15 days, it shall refer the Disputed Matter to relevant judicial
                        forum as per laws of India. All negotiations, statements and / or
                        documentation shall be without prejudice and confidential (unless mutually
                        agreed otherwise). If the Disputed Matter is having a material effect on the
                        operation of the Services (or any of them or part of them), the Parties will
                        use all their respective reasonable endeavors to reduce the elapsed time in
                        reaching a resolution of the Disputed Matter.
                      </p>

                      <h3 className="text-base font-bold mt-4 text-gray-600">General Conditions</h3>
                      <h3 className="text-base font-bold mt-2 text-gray-600">Governing Law</h3>
                      <p className="text-sm text-gray-600">
                        This Agreement is governed by the laws of India, without reference to rules
                        governing choice of laws. If any dispute arises concerning this Agreement,
                        venue shall be laid exclusively in the courts of Ahmedabad, Gujarat which
                        shall have exclusive jurisdiction over such dispute and the Parties consent
                        to the personal jurisdiction of such courts. If legal action or other
                        proceeding of any nature whatsoever is brought in connection with any
                        dispute arising out of this Agreement, the prevailing Party shall be
                        entitled to recover from the non-prevailing Party all attorneys’ fees and
                        costs incurred by the prevailing Party in connection with such dispute.
                      </p>
                      <h3 className="text-base font-bold mt-2 text-gray-600">
                        Entire Agreement; Amendment
                      </h3>
                      <p className="text-sm text-gray-600">
                        This Agreement constitutes the entire agreement between the Parties
                        concerning the subject matter hereof and supersedes all written or oral
                        prior agreements or understandings with respect thereto. This Agreement may
                        be amended from time to time and continued usage of our hardware/software
                        would be under the amended agreement.
                      </p>
                      <h3 className="text-base font-bold mt-2 text-gray-600">Severability</h3>
                      <p className="text-sm text-gray-600">
                        In case of any one or more of the provisions of this Agreement should be
                        held invalid, illegal or unenforceable, each such provision shall be
                        modified, if possible, to the minimum extent necessary to make it valid and
                        enforceable, or if it cannot be so modified, then severed, and the remaining
                        provisions contained in this Agreement shall not in any way be affected or
                        impaired.
                      </p>
                      <h3 className="text-base font-bold mt-2 text-gray-600">Notices</h3>
                      <p className="text-sm text-gray-600">
                        All notices, demands or other communications required or permitted to be
                        given under this Agreement by either Party to the other may be effected by
                        electronic delivery
                      </p>
                      <p className="font-bold mt-1 text-sm text-gray-600">
                        The Parties agree to abide by the terms and conditions of the
                        above-mentioned clauses, as a pre-condition to usage of the Services.
                      </p>
                    </div>
                    <div className="flex items-center mb-4">
                      <input
                        type="checkbox"
                        id="termsAccepted"
                        name="termsAccepted"
                        checked={values.termsAccepted}
                        onChange={handleChange}
                        className="mr-2 h-5 w-5 text-green-600 rounded focus:ring-0"
                      />
                      <label htmlFor="termsAccepted" className="text-sm text-gray-600">
                        I agree & accept IntuIONS Terms & Conditions
                      </label>
                    </div>
                    {errors.termsAccepted && touched.termsAccepted && (
                      <div className="text-red-500 text-sm mb-4">{errors.termsAccepted}</div>
                    )}
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-black text-white px-6 py-2 rounded transition-colors disabled:bg-gray-400"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsConditions;
